import React from "react";
import { useContext } from "react";
import { navigate } from "gatsby";

import { LanguageContext } from "../../../lang";
import "./index.css";

const CostEstimate = () => {
  const {
    translations: { taxiToEindhoven },
  } = useContext(LanguageContext);
  const {
    headingFirstPart,
    headingSecondPart,
    paraText,
    firstPoint,
    secondPoint,
    thirdPoint,
    secondPara,
    fourthPoint,
    fifthPoint,
    buttonText,
    generalHeading,
    generalText,
  } = taxiToEindhoven.costEstimate;
  return (
    <>
      <section id="taxi-to-eindhoven-cost-estimate-section">
        <div className="container">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-12 taxi-to-eindhoven-cost-estimate-col-12">
              <h1 className="taxi-to-eindhoven-cost-estimate-h1">
                {headingFirstPart}
                <br />
                {headingSecondPart}
              </h1>
              <p>
                {paraText}
                <ul className="taxi-to-eindhoven-cost-estimate-ul">
                  <li>{firstPoint}</li>
                  <li>{secondPoint}</li>
                  <li>{thirdPoint}</li>
                </ul>
                {secondPara}
                <ul className="taxi-to-eindhoven-cost-estimate-ul">
                  <li>{fourthPoint}</li>
                  <li>{fifthPoint}</li>
                </ul>
              </p>
              <div
                onClick={() => navigate("/")}
                className="loginButtonStyle mt-1"
              >
                <p className="loginButtonText">{buttonText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <div style={{ padding: 20 }}>
        <h2>{generalHeading}</h2>
        <p>{generalText}</p>
      </div>
    </>
  );
};

export default CostEstimate;
