import React, { Component } from "react";
import HeroSection from "./Hero-Section";
import WhyBookTaxionspot from "./Testimonial/index";
import CostEstimate from "./Cost-Estimate/index";
import "./index.css";

class TaxiToEindhovenComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <HeroSection />
        <WhyBookTaxionspot />
        <CostEstimate />
      </React.Fragment>
    );
  }
}

export default TaxiToEindhovenComponent;
