import React from 'react'
import { Link, navigate } from 'gatsby'

// import TestimonialImage from '../../Image/About/testimonialImage'
import './index.css'
import { useContext } from 'react'
import { LanguageContext } from '../../../lang'

const WhyBookTaxionspot = () => {
  const {
    translations: { taxiToEindhoven }
  } = useContext(LanguageContext)
  const {
    preHeadingText,
    headingText,
    paraText1,
    bullet1,
    bullet2,
    bullet3,
    paraText2,
    paraText3,
    secondHeading,
    secondPara,
    buttonText
  } = taxiToEindhoven.whyTaxiOnSpot
  return (
    <section>
      <div className='container'>
        <div className='row pt-5 pb-5 '>
          <div className='col-lg-6'>
            <h5 className='taxi-to-eindhoven-h5'>{preHeadingText}</h5>
            <h3 className='taxi-to-eindhoven-h3'>{headingText}</h3>
            <br />
            <p className='taxi-to-eindhoven-p'>
              {paraText1}
              <br />
              <br />
              <ul>
                <li>{bullet1}</li>
                <li>{bullet2}</li>
                <li>{bullet3}</li>
              </ul>
              {paraText2}
              <br />
              <br />
              {paraText3}
            </p>
            <br />
            <h3 className='taxi-to-eindhoven-h3-2'>{secondHeading}</h3>
            <br />
            <p className='taxi-to-eindhoven-p'>
              {secondPara}
            </p>
            <div onClick={() => { navigate('/') }} className='eindhovenCalculatePriceButtonStyle'>
              <p className='eindhovenCalculatePriceButtonText'>{buttonText}</p>
            </div>
          </div>
          <div className='col-lg-6 mt-lg-0 mt-md-4 taxi-to-eindhoven-order'>
            {/* <TestimonialImage /> */}
            <img
            className="img-fluid"
            src={require("../../../images/about/about-img-2.png")}
          />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyBookTaxionspot
