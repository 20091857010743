import React from 'react'
import './index.css'
import { useContext } from 'react'
import { LanguageContext } from '../../../lang'
const HeroSection = () => {
  const {
    translations: { taxiToEindhoven }
  } = useContext(LanguageContext)
  const {
    taxi,
    city
  } = taxiToEindhoven.hero
  return (
    <section id='taxi-to-eindhoven-hero-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h1 className='taxi-to-eindhoven-h1'>
              {taxi}{" "}
              <span>{city}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
